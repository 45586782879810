

<template>
  <div class="">
    <h4 class="fw-bold mb-4 d-flex page-title">
      <span class="mr-auto">
        {{ $trans('Tasks') }}
      </span>
      <button v-if="$can('add-task')" @click="addForm = true" class="btn btn-primary btn-sm mr-3">
        <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
             stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
          <line data-v-728e5abc="" x1="12" y1="5" x2="12" y2="19"></line>
          <line data-v-728e5abc="" x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
        {{ $trans('Add new') }}
      </button>
      <button @click="formFilter = !formFilter" class="btn btn-primary btn-sm">
        <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
             stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1 mr-1">
          <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
        </svg>
        {{ $trans('Filter') }}
      </button>
    </h4>
    <div v-if="formFilter" class="card mb-3">
      <div class="card-body">
        <div class="d-flex">
          <div class="form-group mr-3">
            <label>{{ $trans('Status') }}</label>
            <select class="form-control w-200" v-model="filterQuery.status">
              <option value="all">{{ $trans('All') }}</option>
              <option value="0">{{ $trans('Pending') }}</option>
              <option value="2">{{ $trans('Processing') }}</option>
              <option value="1">{{ $trans('Complete') }}</option>
            </select>
          </div>
          <div class="form-group mr-3">
            <label>{{ $trans('Department') }}</label>
            <select class="form-control w-200" v-model="filterQuery.department">
              <option value="all">{{ $trans('All') }}</option>
              <option value="IT">{{ $trans('IT') }}</option>
              <option value="Content">{{ $trans('Content') }}</option>
              <option value="Design">{{ $trans('Design') }}</option>
              <option value="Tools">{{ $trans('Tools') }}</option>
            </select>
          </div>
          <div class="form-group mr-3">
            <label class="d-block">{{ $trans('From date') }}</label>
            <vuejs-datepicker input-class="form-control" class="w-200" v-model="filterQuery.from"></vuejs-datepicker>
          </div>
          <div class="form-group  mr-3">
            <label class="d-block">{{ $trans('To date') }}</label>
            <vuejs-datepicker input-class="form-control" class="w-200" v-model="filterQuery.to"></vuejs-datepicker>
          </div>
        </div>
        <div class="filter-actions d-flex">
          <button @click="reset_filter" class="btn btn-outline-danger btn-sm mr-3">{{ $trans('Reset') }}</button>
          <button @click="index" class="btn btn-outline-primary btn-sm mr-3">
            <div v-if="process_loading" class="spinner-border spinner-border-sm text-secondary"></div>
            {{ $trans('Apply') }}
          </button>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div v-if="tasks && tasks.data" class="table-responsive">
          <div class="list-info mb-3">
            <small>{{ $trans('Results') }}: <strong>{{ tasks.data.length }}</strong> {{ $trans('of') }}
              <strong>{{ tasks.total }}</strong></small>
          </div>
          <table class="table table-striped mb-3">
            <thead>
            <tr>
              <th>{{ $trans('Date') }}</th>
              <th>{{ $trans('Deadline') }}</th>
              <th>{{ $trans('Petitioner') }}</th>
              <th>{{ $trans('Implementer') }}</th>
              <th>{{ $trans('Department') }}</th>
              <th>{{ $trans('Type') }}</th>
              <th>{{ $trans('Project') }}</th>
              <th>{{ $trans('Priority') }}</th>
              <th>{{ $trans('Requirements/Description') }}</th>
              <th>{{ $trans('Detail Link') }}</th>
              <th>{{ $trans('Result URL') }}</th>
              <th>{{ $trans('Dimension') }}</th>
              <th>{{ $trans('Note') }}</th>
              <th v-if="$can('it-tasks') || $can('content-tasks' || $can('design-tasks') || $can('all-tasks')  )">{{ $trans('Manager Note') }}</th>
              <th>{{ $trans('Status') }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(task,index) in tasks.data" v-bind:key="index">
              <td>{{ task.date }}</td>
              <td>{{ task.deadline_date }}</td>
              <td>
                <slot v-if="task.user">{{ task.user.username }}</slot>
              </td>
              <td>
                <slot v-if="task.implementer">{{ task.implementer.username }}</slot>
                <slot v-else>-</slot>
              </td>
              <td>{{ task.department }}</td>
              <td>{{ $trans(task.type) }}</td>
              <td>
                <slot v-if="task.project">{{ task.project.name }}</slot>
                <slot v-else>{{task.other_project}}</slot>
              </td>
              <td>
                <span v-if="task.priority == 'General'" class="badge badge-pill badge-info">{{ $trans(task.priority) }}</span>
                <span v-if="task.priority == 'Urgent'" class="badge badge-pill badge-danger">{{ $trans(task.priority) }}</span>
              </td>
              <td class="pre-line">{{ $trans(task.desc) }}</td>
              <td>
                <span v-html="$short_link(task.reference_link)" class="sort-text d-block"></span>
              </td>
              <td>
                <span v-html="$short_link(task.result_url)" class="sort-text d-block"></span>
              </td>
              <td>{{ $trans(task.dimension) }}</td>
              <td class="pre-line">{{ task.note }}</td>
              <td v-if="$can('it-tasks') || $can('content-tasks' || $can('design-tasks') || $can('all-tasks')  )" class="pre-line">
                <slot v-if="task.notes">
                    <span class="d-block" v-for="(text,k) in task.notes" :key="k">{{text}}</span>
                </slot>
              </td>
              <td>
                <span v-if="task.status == 1" class="badge badge-pill badge-success">{{$trans('Complete')}}</span>
                <span v-if="task.status == 0" class="badge badge-pill badge-secondary">{{$trans('Pending')}}</span>
                <span v-if="task.status == 2" class="badge badge-pill badge-primary">{{$trans('Processing')}}</span>
                <span v-if="task.status == -1" class="badge badge-pill badge-danger">{{$trans('Cancel')}}</span>
              </td>

              <td class="text-right td-control">
                <slot v-if="task.user_id == user._id && task.status!=1">
                  <a @click="edit(task)" class="mr-1" href="javascript:void(0)">
                    <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
                         stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                    </svg>
                  </a>
                </slot>
                <slot v-if="task.status == 0 && $can('it-tasks') && task.department=='IT'">
                  <button @click="start_doing(task)" class="btn btn-info btn-xs">{{$trans('Start doing')}}</button>
                  <button @click="cancel_task(task)" class="btn btn-danger btn-xs">{{$trans('Cancel')}}</button>
                </slot>
                <slot v-if="task.status == 0 && $can('content-tasks') && task.department=='Content'">
                  <button @click="start_doing(task)" class="btn btn-info btn-xs">{{$trans('Start doing')}}</button>
                  <button @click="cancel_task(task)" class="btn btn-danger btn-xs">{{$trans('Cancel')}}</button>
                </slot>
                <slot v-if="task.status == 0 && $can('design-tasks') && task.department=='Design'">
                  <button @click="start_doing(task)" class="btn btn-info btn-xs">{{$trans('Start doing')}}</button>
                  <button @click="cancel_task(task)" class="btn btn-danger btn-xs">{{$trans('Cancel')}}</button>
                </slot>
                <slot v-if="task.status == 2 && task.implementer && task.implementer._id == user._id">
                  <button @click="confirm_task(task)" class="btn btn-success btn-xs">{{$trans('Done')}}</button>
                </slot>
                <slot v-if="$can('it-tasks') || $can('content-tasks') || $can('design-tasks') || $can('all-tasks')">
                  <button @click="form_note(task)" class="btn btn-xs btn-secondary" title="Add Note">
                    <svg data-v-0acc1ee4="" viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line data-v-0acc1ee4="" data-v-728e5abc="" x1="12" y1="5" x2="12" y2="19"></line><line data-v-0acc1ee4="" data-v-728e5abc="" x1="5" y1="12" x2="19" y2="12"></line></svg>
                  </button>
                </slot>
              </td>
            </tr>
            </tbody>
          </table>
          <paginate v-if="tasks && tasks.data && tasks.last_page"
                    :page-count="tasks.last_page"
                    :click-handler="paginate"
                    :prev-text="$trans('Previous')"
                    :next-text="$trans('Next')"
                    :page-class="'page-item'"
                    :prev-class="'page-item'"
                    :next-class="'page-item'"
                    :page-link-class="'page-link'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"
                    :container-class="'pagination'">
          </paginate>
        </div>
      </div>
    </div>
    <modal v-if="addForm && $can('add-task')" v-bind:width="'800px'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{ $trans('Add request') }}
          <button @click="addForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <small>{{ $trans('Fields Marked with (*) are required') }}</small>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{ alert.message }}
        </div>
        <div class="form-group">
          <label>{{ $trans('Department') }} (*)</label>
          <div class="">
            <div v-for="(item,k) in task_group" :key="k" class="form-check form-check-inline">
              <input v-model="addFields.department" class="form-check-input" type="radio" :id="'inlineRadio_'+k" :value="k">
              <label class="form-check-label" :for="'inlineRadio_'+k">{{k}}</label>
            </div>
          </div>
          <slot v-if="errors && errors['department']">
            <div class="text-danger" v-for="error in errors['department']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{ $trans('Task type') }} (*)</label>
          <select class="form-control" v-model="addFields.type">
            <option value="">{{$trans('Select a option')}}</option>
            <option v-for="(item,k) in task_group[addFields.department]" :key="k" :value="item">{{$trans(item)}}</option>
          </select>
          <slot v-if="errors && errors['type']">
            <div class="text-danger" v-for="error in errors['type']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{ $trans('Project') }} (*)</label>
          <Select2 class="" v-model="addFields.project_id" :options="projects"/>
          <slot v-if="errors && errors['project_id']">
            <div class="text-danger" v-for="error in errors['project_id']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div v-if="addFields.project_id =='other'" class="form-group">
          <label>{{ $trans('Other Project') }}</label>
          <input type="text" class="form-control" v-model="addFields.other_project">
          <small>{{$trans('Clearly state the project name, work section or domain...')}}</small>
        </div>
        <div class="form-group">
          <label>{{ $trans('Requirements/Description') }} (*)</label>
          <textarea class="form-control" v-model="addFields.desc"></textarea>
          <slot v-if="errors && errors['desc']">
            <div class="text-danger" v-for="error in errors['desc']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div v-if="addFields.department=='Content'" class="form-group">
          <label>{{ $trans('Detail Link') }} (*)</label>
          <input type="text" class="form-control" v-model="addFields.reference_link">
          <slot v-if="errors && errors['desc']">
            <div class="text-danger" v-for="error in errors['reference_link']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group" v-if="addFields.department=='Design'">
          <label class="d-block">{{ $trans('Dimension') }}</label>
          <input type="text" class="form-control" v-model="addFields.dimension">
        </div>
        <div class="form-group" v-if="addFields.department=='Design'">
          <label class="d-block">{{ $trans('Result URL') }}</label>
          <input type="text" class="form-control" v-model="addFields.result_url">
        </div>
        <div class="form-group">
          <label>{{ $trans('Priority') }}</label>
          <div>
            <div v-for="(item,k) in task_priority" :key="k" class="form-check form-check-inline">
              <input v-model="addFields.priority" class="form-check-input" type="radio" :id="'task_priority_'+k" :value="item">
              <label class="form-check-label" :for="'task_priority_'+k">{{$trans(item)}}</label>
            </div>
          </div>
          <slot v-if="errors && errors['priority']">
            <div class="text-danger" v-for="error in errors['priority']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label class="d-block">{{ $trans('Deadline') }}</label>
          <vuejs-datepicker input-class="form-control" class="w-200" v-model="addFields.deadline"></vuejs-datepicker>
        </div>
        <div class="form-group">
          <label>{{ $trans('Note') }} (*)</label>
          <textarea class="form-control" v-model="addFields.note"></textarea>
        </div>
        <button @click="store" class="btn btn-primary">
          <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
          {{ $trans('Add & Request') }}
        </button>
      </slot>
    </modal>
    <modal v-if="editForm" v-bind:width="'800px'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{ $trans('Edit request') }}
          <button @click="editForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <small>{{ $trans('Fields Marked with (*) are required') }}</small>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{ alert.message }}
        </div>
        <div class="form-group">
          <label>{{ $trans('Department') }} (*)</label>
          <div class="">
            <div v-for="(item,k) in task_group" :key="k" class="form-check form-check-inline">
              <input v-model="updateFields.department" class="form-check-input" type="radio" :id="'inlineRadio_'+k" :value="k">
              <label class="form-check-label" :for="'inlineRadio_'+k">{{k}}</label>
            </div>
          </div>
          <slot v-if="errors && errors['department']">
            <div class="text-danger" v-for="error in errors['department']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{ $trans('Task type') }} (*)</label>
          <select class="form-control" v-model="updateFields.type">
            <option value="">{{$trans('Select a option')}}</option>
            <option v-for="(item,k) in task_group[updateFields.department]" :key="k" :value="item">{{$trans(item)}}</option>
          </select>
          <slot v-if="errors && errors['type']">
            <div class="text-danger" v-for="error in errors['type']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{ $trans('Project') }} (*)</label>
          <Select2 class="" v-model="updateFields.project_id" :options="projects"/>
          <slot v-if="errors && errors['project_id']">
            <div class="text-danger" v-for="error in errors['project_id']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div v-if="updateFields.project_id =='other'" class="form-group">
          <label>{{ $trans('Other Project') }}</label>
          <input type="text" class="form-control" v-model="updateFields.other_project">
          <small>{{$trans('Clearly state the project name, work section or domain...')}}</small>
        </div>
        <div class="form-group">
          <label>{{ $trans('Requirements/Description') }} (*)</label>
          <textarea class="form-control" v-model="updateFields.desc"></textarea>
          <slot v-if="errors && errors['desc']">
            <div class="text-danger" v-for="error in errors['desc']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div v-if="updateFields.department=='Content'" class="form-group">
          <label>{{ $trans('Detail Link') }} (*)</label>
          <input type="text" class="form-control" v-model="updateFields.reference_link">
          <slot v-if="errors && errors['desc']">
            <div class="text-danger" v-for="error in errors['reference_link']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group" v-if="updateFields.department=='Design'">
          <label class="d-block">{{ $trans('Dimension') }}</label>
          <input type="text" class="form-control" v-model="updateFields.dimension">
        </div>
        <div class="form-group" v-if="updateFields.department=='Design'">
          <label class="d-block">{{ $trans('Result URL') }}</label>
          <input type="text" class="form-control" v-model="updateFields.result_url">
        </div>
        <div class="form-group">
          <label>{{ $trans('Priority') }}</label>
          <div>
            <div v-for="(item,k) in task_priority" :key="k" class="form-check form-check-inline">
              <input v-model="updateFields.priority" class="form-check-input" type="radio" :id="'task_priority_'+k" :value="item">
              <label class="form-check-label" :for="'task_priority_'+k">{{$trans(item)}}</label>
            </div>
          </div>
          <slot v-if="errors && errors['priority']">
            <div class="text-danger" v-for="error in errors['priority']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label class="d-block">{{ $trans('Deadline') }}</label>
          <vuejs-datepicker input-class="form-control" class="w-200" v-model="updateFields.deadline"></vuejs-datepicker>
        </div>
        <div class="form-group">
          <label>{{ $trans('Note') }} (*)</label>
          <textarea class="form-control" v-model="updateFields.note"></textarea>
        </div>
        <button @click="update" class="btn btn-primary">
          <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
          {{ $trans('Update') }}
        </button>
      </slot>
    </modal>
    <modal v-if="confirmForm" v-bind:width="'800px'">
      <slot slot="body">
        <h5 class="mb-3">
          {{ $trans('Confirm') }}
          <button @click="confirmForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <small>{{ $trans('Fields Marked with (*) are required') }}</small>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{ alert.message }}
        </div>
        <div class="form-group">
          <label class="d-block">{{ $trans('Result URL') }}</label>
          <input type="text" class="form-control" v-model="task.result_url">
        </div>
        <button @click="done_task(task)" class="btn btn-primary">
          <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
          {{ $trans('Update & Confirm') }}
        </button>
      </slot>
    </modal>
    <modal v-if="formNote" v-bind:width="'800px'">
      <slot slot="body">
        <h5 class="mb-3">
          {{ $trans('Add Note') }}
          <button @click="formNote = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <small>{{ $trans('Fields Marked with (*) are required') }}</small>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{ alert.message }}
        </div>
        <div class="form-group">
          <label class="d-block">{{ $trans('Note') }}</label>
          <input type="text" class="form-control" v-model="noteFields.text">
          <slot v-if="errors && errors['text']">
            <div class="text-danger" v-for="error in errors['text']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <button @click="add_note(task)" class="btn btn-primary">
          <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
          {{ $trans('Add') }}
        </button>
      </slot>
    </modal>
  </div>
</template>
<script >
import axios from "axios";
import Pusher from "pusher-js";
export default {
  name: "Task",
  data: function () {
    return {
      filterQuery: {
        page: 1,
        from: null,
        to: new Date(),
        status: 'all',
        department: 'all',
      },
      tasks: null,
      process_loading: false,
      detailForm: false,
      task: null,
      alert: null,
      errors: {},
      response_users: null,
      formFilter:false,
      addForm:false,
      addFields:{
        department:'IT',
        priority:'General',

      },
      process:false,
      editForm:false,
      updateFields:null,
      process_doing:false,
      confirmForm:false,
      formNote:false,
      noteFields:{
        text:''
      }
    }
  },
  mounted() {
    var today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    if (month == 1) {
      month = 12;
      year = year - 1;
    }else {
      month = month -1
    }
    this.filterQuery.from = new Date(year, month, 1);
  },
  methods: {
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.index();
    },
    index: function () {
      this.process_loading = true
      axios({url: this.$root.$data.api_url + '/api/tasks', params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.tasks = resp.data;
            this.process_loading = false
            this.export_requests = null
          })
          .catch(err => {
            console.log(err)
          })
    },
    reset_filter: function () {
      var today = new Date();
      let month = today.getMonth();
      let year = today.getFullYear();
      if (month == 1) {
        month = 12;
        year = year - 1;
      }else {
        month = month -1
      }
      this.filterQuery = {
        from: new Date(year, month, 1),
        to: new Date(),
        page: 1,
        status: 'all',
        department: 'all',
      }
      this.index()
    },
    detail: function (request) {
      axios({url: this.$root.$data.api_url + '/api/tasks/' + request._id, method: 'GET'})
          .then(resp => {
            this.request = resp.data.data;
            this.detailForm = true;
            this.detail_table = resp.data.data.detail;
          })
          .catch(err => {
            console.log(err)
          })
    },
    get_projects: function () {
      axios({url: this.$root.$data.api_url + '/api/user/projects', params: {}, method: 'GET'})
          .then(resp => {
            this.projects_res = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    get_users: function () {
      axios({url: this.$root.$data.api_url + '/api/all_user', params: {roles: []}, method: 'GET'})
          .then(resp => {
            this.response_users = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    store:function () {
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = this.addFields
        data.detail = this.detail_table
        data.draft = 0
        axios({url: this.$root.$data.api_url + '/api/tasks', data: data, method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.addForm = false;
                this.index();
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    edit:function (task) {
      axios({url: this.$root.$data.api_url + '/api/tasks/' + task._id, method: 'GET'})
          .then(resp => {
            this.updateFields = resp.data.data;
            this.editForm = true;
          })
          .catch(err => {
            console.log(err)
          })
    },
    update: function () {
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = this.updateFields
        axios({url: this.$root.$data.api_url + '/api/tasks/' + data._id, data: data, method: 'PUT'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.editForm = false;
                this.index();
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    start_doing:function (task) {
      if (this.process_doing == false) {
        this.process_doing = true;
        this.errors = {}
        this.alert = null
        axios({url: this.$root.$data.api_url + '/api/tasks/doing/' + task._id, data: {}, method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.editForm = false;
                this.index();
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process_doing = false;
            })
            .catch(err => {
              console.log(err)
              this.process_doing = false;
            })
      }
    },
    cancel_task:function (task){
      if (this.process_doing == false) {
        this.process_doing = true;
        this.errors = {}
        this.alert = null
        axios({url: this.$root.$data.api_url + '/api/tasks/cancel/' + task._id, data: {}, method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.editForm = false;
                this.index();
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process_doing = false;
            })
            .catch(err => {
              console.log(err)
              this.process_doing = false;
            })
      }
    },
    confirm_task(task){
      this.task = task
      this.confirmForm = true
    },
    done_task:function (task) {
      if (this.process_doing == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = this.task
        axios({url: this.$root.$data.api_url + '/api/tasks/done/' + task._id, data:data, method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.confirmForm = false;
                this.index();
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    form_note(task){
      this.task = task
      this.formNote = true
    },
    add_note:function (task){
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = this.noteFields
        axios({url: this.$root.$data.api_url + '/api/tasks/addNote/' + task._id, data:data, method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.formNote = false;
                this.index();
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    destroy:function (){

    }
  },
  created() {
    this.index()
    this.get_projects()
    this.get_users()

    // Real time
    let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY,
        {
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          encrypted: true
        }
    );
    const channel = pusher.subscribe('jobtask-channel');
    channel.bind('jobtask-event', (e) => {
      console.log(e)
      if (this.tasks && this.tasks.data) {
        if (e.action == 'created') {
          let task = e.task
          let currentUser_permissions = this.currentUser_permissions
          if(task.user_id == this.user.__id){
            this.tasks.data.unshift(e.task);
          }else if (currentUser_permissions.includes('all-tasks')) {
            this.tasks.data.unshift(e.task);
          }else if (currentUser_permissions.includes('it-tasks') &&  task.department =='IT'){
            this.tasks.data.unshift(e.task);
          }else if (currentUser_permissions.includes('content-tasks') &&  task.department =='Content'){
            this.tasks.data.unshift(e.task);
          }else if (currentUser_permissions.includes('design-tasks') &&  task.department =='Design'){
            this.tasks.data.unshift(e.task);
          }else if (currentUser_permissions.includes('tools-tasks') &&  task.department =='Tools'){
            this.tasks.data.unshift(e.task);
          }

        } else {
          var arr = this.tasks.data;
          var tmp_arr = [];
          var current_id = e.order._id;
          arr.forEach(function (el, index) {
            if (current_id == el._id) {
              tmp_arr[index] = e.task;
            } else {
              tmp_arr[index] = el;
            }
          });
          this.tasks.data = tmp_arr;
        }
      }
    });
  },
  computed: {
    projects: function () {
      if (!this.projects_res) {
        return [];
      }
      let projects = [
        {
          id:'other',
          text:'Khác'
        }
      ];
      let projects_res = this.projects_res;
      projects_res.forEach((el) => {
        let item = {
          id: el._id,
          text: el.name
        }
        projects.push(item)
      })

      return projects;
    },
    partners: function () {
      if (!this.partners_res) {
        return [];
      }
      let partners = [];
      let partners_res = this.partners_res;
      partners_res.forEach((el) => {
        let item = {
          id: el._id,
          text: el.name
        }
        partners.push(item)
      })

      return partners;
    },
    user: function () {
      return this.$root.user
    },
    task_group: function () {
      return this.$root.$data.global_settings.tasks.group
    },
    task_priority: function () {
      return this.$root.$data.global_settings.tasks.priority
    },
    currentUser_permissions:function () {
      return this.$root.currentUser_permissions
    }

  }
}
</script>

<style scoped>

</style>